import MainLayout from "@/pcomponents/main/layout/layout";
import LayoutListArea from "@/pages/main/math/page_layout/common/list";

function PageLayoutListPgae() {

  return (
    <MainLayout>
      <LayoutListArea></LayoutListArea>
    </MainLayout>
  );
};
export default PageLayoutListPgae;