import strFunc from "@/lib/lyg/string";

function BoxOneTwoMatter(props: any) {
  let myProps = {
    matter_data: {
      idx: 0,
      type_id: "",
      part_arr: [],
      answer_idx_arr: [2],
      is_show_answer: true,
    },
    ...props
  };
  let matter_data = myProps.matter_data;

  const display_part_value = (part_idx: number) => {
    let part_value: any = "";
    let part_len = matter_data.part_arr.length;
    if ((part_len - 1) >= part_idx) {
      part_value = matter_data.part_arr[part_idx];
    }

    part_value = (
      <span>
        {part_value}
      </span>
    );
    if (strFunc.str_in_array(part_idx, matter_data.answer_idx_arr) != -1) {
      if (matter_data.is_show_answer) {
        part_value = (
          <span className="answer_span">
            {part_value}
          </span>
        );
      } else {
        part_value = "";
      }
    }

    return part_value;
  };

  return (
    <div className="matter_wrap">
      <span className="matter_idx_num">
        {matter_data.idx + 1}
      </span>
      <table className="matter_base_table">
        <tbody>
          <tr>
            <td colSpan={2}>
              {display_part_value(0)}
            </td>
          </tr>
          <tr>
            <td>
              {display_part_value(1)}
            </td>
            <td>
              {display_part_value(2)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default BoxOneTwoMatter;