import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from "./area/search";
import strFunc from '@/lib/lyg/string';
import ShowMatterPage from "@/pcomponents/common/content/math/show_page";

function ShowPageArea(props: any) {
  let myProps = {
    ...props
  };
  const ShowMatterPageRef = useRef<any>(null);
  const [listOpt, setListOpt] = useState({
    s_stu_grade: "8",
    s_stu_season: "1",
    s_section_seq: "",
    s_menu_seq: "",
  });
  const default_page_info = {
    a_seq: "",
    a_menu_seq: "",
    a_title: "",
    section_info: null,
    menu_info: null,
    matter_arr: [],
    matter_data_arr: [],
  };
  const [page_info, set_page_info] = useState({ ...default_page_info });
  let [is_show_first_answer, set_is_show_first_answer] = useState(false);
  let [is_show_answer, set_is_show_answer] = useState(false);

  useEffect(() => {

  }, []);

  const get_page_one_by_ajax = (inData: any) => {
    let opt_obj = {
      s_menu_seq: listOpt.s_menu_seq,
      ...inData
    };
    let form_json_data = {
      s_menu_seq: opt_obj.s_menu_seq,
      except_page_seq_arr: [page_info.a_seq],
    };

    if (strFunc.is_empty(form_json_data.s_menu_seq)) {
      set_page_info({
        ...default_page_info
      });
      return false;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_page/get_page_one', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_page_info({
            ...default_page_info,
            ...response.data["data"]["page_info"]
          });
        } else {
          set_page_info({
            ...default_page_info
          });
        }
      });
  };

  const go_print=()=>{
    if(ShowMatterPageRef.current){
      ShowMatterPageRef.current.print_page();
    }
  };

  const open_anser_popup=()=>{
    if(ShowMatterPageRef.current){
      let answer_url=ShowMatterPageRef.current.get_qrcode_url_by_page({});
      window.open(answer_url);
    }
  };

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          get_page_one_by_ajax={get_page_one_by_ajax}
        ></SearchArea>
      </div>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
        <button className={"btn-m " + (is_show_answer ? "btn-sky" : "btn-dark")}
          onClick={() => { set_is_show_answer(!is_show_answer); }} style={{ marginLeft: 10 }}>정답보기</button>
        <button className={"btn-m " + (is_show_first_answer ? "btn-sky" : "btn-dark")}
          onClick={() => { set_is_show_first_answer(!is_show_first_answer); }} style={{ marginLeft: 10 }}>처음정답보기</button>
        <button className={"btn-m btn-dark"} onClick={go_print} style={{ marginLeft: 10 }}>프린트</button>
        <button className={"btn-m btn-dark"} onClick={()=>{open_anser_popup();}} style={{ marginLeft: 10 }}>정답지팝업</button>
        <button className={"btn-m btn-dark"}
          onClick={() => { get_page_one_by_ajax({}); }} style={{ marginLeft: 10 }}>다른문제</button>
      </div>
      <div style={{ width: "21cm", margin: "0 auto", marginTop: 20 }} >
        <ShowMatterPage
          ref={ShowMatterPageRef}
          page_info={page_info}
          is_show_first_answer={is_show_first_answer}
          is_show_answer={is_show_answer}
        ></ShowMatterPage>
      </div>
    </div>
  );
};
export default ShowPageArea;