import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search";
import strFunc from '@/lib/lyg/string';

function LayoutListArea(props: any) {
  let myProps = {
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  useEffect(() => {

  }, []);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    form_json_data["sc"]=listAggridRef.current.getListSc();
    setListOpt(form_json_data);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_layout/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let no_saved_row_idx_arr=[];
    let del_select_rows=[];
    for(let i=0;i<selected_row.length;i++){
      let tmp_row=selected_row[i];
      if(strFunc.is_empty(tmp_row["a_seq"])){
        no_saved_row_idx_arr.push(tmp_row["idx_num"]);
      }else{
        del_select_rows.push(tmp_row);
      }
    }
    if(no_saved_row_idx_arr.length>0){
      let all_rows=listAggridRef.current.getRows();
      let remain_rows=[];
      for(let i=0;i<all_rows.length;i++){
        let tmp_row=all_rows[i];
        if(strFunc.str_in_array(tmp_row["idx_num"],no_saved_row_idx_arr)==-1){
          remain_rows.push(all_rows[i]);
        }
      }
      listAggridRef.current.setInfoArr(remain_rows);
    }
    if(del_select_rows.length===0){
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: del_select_rows
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_layout/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goSaveSelect = () => {
    let selected_row = listAggridRef.current.getRows();//listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("저장하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_layout/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const add_new_row = () => {
    let tmp_rowData: any = {
      a_seq: "",
      a_title: "",
      a_x_split:2,
      a_direction:"down",//down,right
      a_design_sort:"",
    };
    listAggridRef.current.addRowData({
      data: [tmp_rowData],
      focus_key: "a_title",
    });
    setInfoArr([
      ...infoArr,
      ...[tmp_rowData]
    ]);
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    return render_str;
  }, []);

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          list={list}
        ></SearchArea>
      </div>
      <div className="text-right">
        <button className="btn btn-dark" onClick={() => { add_new_row(); }} >신규</button>
        <button className="btn btn-blue ml-1" onClick={() => { goSaveSelect(); }} >저장</button>
        <button className="btn btn-red ml-1" onClick={goDelete} >삭제</button>
      </div>
      <div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_grid: true,
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: true,
            is_add_checkbox: true,
            floatingFilter: false,
            is_add_rowDrag: true,
            onGridReady: () => {
              list({});
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer
          }}
        ></ListAggrid>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}
          onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
      </div>
    </div>
  );
};
export default LayoutListArea;