import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt=myProps.listOpt;

  const [section_listopt, set_section_listopt] = useState({
    s_stu_grade: listOpt.s_stu_grade,
    s_stu_season: listOpt.s_stu_season,
    order_id:"a_order_num",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [menu_listopt, set_menu_listopt] = useState({
    s_stu_grade: listOpt.s_stu_grade,
    s_stu_season: listOpt.s_stu_season,
    s_section_seq: listOpt.s_section_seq,
    order_id:"a_order_num",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",
  });
  const [section_info_arr, set_section_info_arr] = useState([]);
  const [menu_info_arr, set_menu_info_arr] = useState([]);

  useEffect(() => {
    get_section_list_by_ajax({});
    get_menu_list_by_ajax({});
  }, []);

  const get_section_list_by_ajax = (inOptObj: any) => {
    let form_json_data = {
      ...section_listopt,
      ...inOptObj
    };
    set_section_listopt(form_json_data);
    
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_section/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_section_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_menu_list_by_ajax = (inOptObj: any) => {
    let form_json_data = {
      ...menu_listopt,
      ...inOptObj
    };
    set_menu_listopt(form_json_data);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_menu/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_menu_info_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  let section_select_option_arr=[{value:"",text:"전체"}];
  for(let i=0;i<section_info_arr.length;i++){
    let item=section_info_arr[i];
    section_select_option_arr.push({value:item["a_seq"],text:item["a_title"]});
  }
  let menu_select_option_arr=[{value:"",text:"전체"}];
  for(let i=0;i<menu_info_arr.length;i++){
    let item=menu_info_arr[i];
    menu_select_option_arr.push({value:item["a_math_type_id"],text:item["a_title"]});
  }

  return (
    <div>
      <div>
        학년:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_grade_option_arr
            ]}
            value={section_listopt.s_stu_grade}
            on_change={(inData: any) => {
              get_section_list_by_ajax({
                s_stu_grade: inData.value
              });
              get_menu_list_by_ajax({
                s_stu_grade: inData.value
              });
              myProps.list({ now_page: 1, s_stu_grade: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        학기:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_season_option_arr
            ]}
            value={section_listopt.s_stu_season}
            on_change={(inData: any) => {
              get_section_list_by_ajax({
                s_stu_season: inData.value
              });
              get_menu_list_by_ajax({
                s_stu_season: inData.value
              });
              myProps.list({ now_page: 1, s_stu_season: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        섹션:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={section_select_option_arr}
            value={listOpt.s_section_seq}
            on_change={(inData: any) => {
              get_menu_list_by_ajax({
                s_section_seq: inData.value
              });
              myProps.list({ now_page: 1, s_section_seq: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        메뉴:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={menu_select_option_arr}
            value={listOpt.s_math_type_id}
            on_change={(inData: any) => {
              myProps.list({ now_page: 1, s_math_type_id: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;