import strFunc from "@/lib/lyg/string";
import React from "react";

function HorizontalMatter(props: any) {
  let myProps = {
    matter_data: {
      idx: 0,
      part_arr: [0, "+", 0, "=",0],
      type_id: "",
      answer_idx_arr: [4],
      is_show_answer: false,
    },
    ...props
  };
  let matter_data = myProps.matter_data;

  const display_part_value = (part_idx: number) => {
    let part_value: any = "";
    let part_len = matter_data.part_arr.length;
    if ((part_len - 1) >= part_idx) {
      part_value = matter_data.part_arr[part_idx];
    }

    if (part_value == "*") {
      part_value = "×";
    }else if(part_value == "/"){
      part_value = "÷";
    }

    if (strFunc.str_in_array(part_idx, matter_data.answer_idx_arr) != -1) {
      if (matter_data.is_show_answer) {
        part_value = (
          <td>
            <span className="answer_span">
              {part_value}
            </span>
          </td>
        );
      } else {
        let tmp_anser_class_name="answer_box";
        if(matter_data.part_arr.length-1==part_idx){
          tmp_anser_class_name="";
        }
        part_value = (
          <td className={tmp_anser_class_name} style={{minWidth:"20px"}}>
            
          </td>
        );
      }
    }else{
      part_value = (
        <td>
          <span>
            {part_value}
          </span>
        </td>
      );
    }

    return part_value;
  };

  const get_td_tags=()=>{
    let td_tags:any=matter_data.part_arr.map((item:any,idx:number)=>{
      return (
        <React.Fragment key={idx}>
          {display_part_value(idx)}
        </React.Fragment>
      );
    });
    return td_tags;
  };

  return (
    <div className="matter_wrap matter_wrap_align_left">
      <span className="matter_idx_num">
        {matter_data.idx + 1}
      </span>
      <table className="matter_inline_table">
        <tbody>
          <tr>
            {get_td_tags()}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default HorizontalMatter;