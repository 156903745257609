import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    list: (inData: any) => { },
    matter_layout_arr: [],
    math_theme_arr: [],
    ...props
  };
  let listOpt = myProps.listOpt;
  let matter_layout_arr = myProps.matter_layout_arr;
  let math_theme_arr=myProps.math_theme_arr;

  const on_change_search_input = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let new_listOpt = { ...listOpt };
    new_listOpt[name] = value;
    myProps.setListOpt(new_listOpt);
  };

  let matter_layout_select_option_arr = [{ value: "", text: "전체" }];
  for (let i = 0; i < matter_layout_arr.length; i++) {
    let item = matter_layout_arr[i];
    matter_layout_select_option_arr.push({ value: item["a_id"], text: item["a_title"] });
  }

  let math_theme_select_option_arr = [{ value: "", text: "전체" }];
  for (let i = 0; i < math_theme_arr.length; i++) {
    let item = math_theme_arr[i];
    math_theme_select_option_arr.push({ value: item["a_title"], text: item["a_title"] });
  }

  return (
    <div>
      <div>
        레이아웃:
        <span className="ml-2" style={{ display: "inline-block" }}>
          <SelectBoxBar
            valueTextArr={matter_layout_select_option_arr}
            value={listOpt.s_layout_id}
            on_change={(inData: any) => {
              myProps.list({ now_page: 1, s_layout_id: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        테마:
        <span className="ml-2" style={{ display: "inline-block" }}>
          <SelectBoxBar
            valueTextArr={math_theme_select_option_arr}
            value={listOpt.s_theme}
            on_change={(inData: any) => {
              myProps.list({ now_page: 1, s_theme: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-2">
        <input type="text" className="border"
          value={listOpt.s_title_like}
          name="s_title_like"
          onChange={on_change_search_input}
          onKeyUp={(e: any) => {
            let key = e.key || e.keyCode;
            if (key == "Enter") {
              myProps.list({ now_page: 1 });
            }
          }}
          placeholder="제목"
          style={{ paddingLeft: 3, width: 100 }} />
        <button className="btn btn-dark ml-1"
          onClick={() => {
            myProps.list({ now_page: 1 });
          }}>검색</button>
      </div>
    </div>
  );
};
export default SearchArea;