import MainLayout from "@/pcomponents/main/layout/layout";
import ShowPageArea from "@/pages/main/math/page/common/show_page";

function ShowMatterPage() {

  return (
    <MainLayout>
      <ShowPageArea></ShowPageArea>
    </MainLayout>
  );
};
export default ShowMatterPage;