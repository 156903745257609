//import ShowMatterPage from "@/pcomponents/common/content/math/show_page";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import prj_logo_img from "@/img/logo/snt_logo_2.png";
import { useReactToPrint } from 'react-to-print';
import strFunc from "@/lib/lyg/string";
import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
import MatterTagsArea from "./area/matter_tags";
var QRCode = require('qrcode');

const ShowMatterPage = forwardRef((props:any, ref) => {
  let myProps={
    page_info:{},
    menu_info:{},
    matter_data_arr:[],
    is_show_first_answer:false,
    is_show_answer:false,
    ...props
  };
  let page_info=myProps.page_info;
  
  let menu_info:any=null;
  let section_info:any=null;
  let matter_data_arr:any=[];
  if(page_info.menu_info){
    menu_info=page_info.menu_info;
  }
  if(page_info.section_info){
    section_info=page_info.section_info;
  }
  if(page_info.matter_data_arr){
    matter_data_arr=page_info.matter_data_arr;
  }
  let is_show_first_answer=myProps.is_show_first_answer;
  let is_show_answer=myProps.is_show_answer;

  const printRef = useRef<HTMLDivElement>(null);
  const qrcode_canvas_ref=useRef<any>(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
  });

  useEffect(()=>{
    make_qrcode_by_page({
      page_seq:page_info.a_seq
    });
  },[page_info.a_seq]);

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    print_page,get_qrcode_url_by_page
  }));

  const print_page=()=>{
    handlePrint();
  };

  const get_qrcode_url_by_page=(inData:any)=>{
    let opt_obj={
      page_seq:page_info.a_seq,
      ...inData
    };
    let url_query_str="?page_seq="+opt_obj.page_seq;
    let qrcode_url=process.env.REACT_APP_MATH_URL+'/math/view_of_url'+url_query_str;
    return qrcode_url;
  };

  const make_qrcode_by_page=(inData:any)=>{
    let opt_obj={
      page_seq:page_info.a_seq,
      ...inData
    };
    let qrcode_url=get_qrcode_url_by_page(opt_obj);
    QRCode.toCanvas(qrcode_canvas_ref.current,qrcode_url, function (error:any) {
      if (error) console.error(error)
      //console.log('success!');
      qrcode_canvas_ref.current.style.width="90px";
      qrcode_canvas_ref.current.style.height="90px";
    });
  };

  const get_matter_page_box_tags=()=>{
    if(strFunc.is_empty(menu_info)){
      return false;
    }
    let split_num=menu_info.a_layout_split;
    let split_each_num=0;
    let split_div_per=100;
    if(split_num>0){
      split_each_num=Math.ceil(matter_data_arr.length/split_num);
      split_div_per=Math.floor(100/split_num);
    }

    let page_box_arr=[];
    for(let i=0;i<split_num;i++){
      let page_box_data:any={
        matter_data_arr:[]
      };
      let start_idx=i*split_each_num;
      let max_idx=(i+1)*split_each_num;
      for(let j=0;j<matter_data_arr.length;j++){
        if(j>=start_idx&&j<max_idx){
          page_box_data.matter_data_arr.push(matter_data_arr[j]);
        }
      }
      page_box_arr.push(page_box_data);
    }

    let matter_page_box_tags=page_box_arr.map((item:any,idx:number)=>{
      let row_matter_data_arr=item["matter_data_arr"];
      return (
        <div className="matter_page_box" key={idx} style={{width:split_div_per+"%"}}>
          <MatterTagsArea
            row_matter_data_arr={row_matter_data_arr}
            page_box_idx={idx}
            is_show_first_answer={is_show_first_answer}
            is_show_answer={is_show_answer}
          ></MatterTagsArea>
        </div>
      );
    });
    return matter_page_box_tags;
  };

  let matter_title_str=page_info["a_title"];
  if(page_info.menu_info){
    matter_title_str=page_info.menu_info["a_order_num"]+") "+page_info.menu_info["a_title"];
  }
  let matter_msg_str="";
  let section_text_str="";
  if(page_info.section_info){
    section_text_str=page_info.section_info["a_order_num"]+". "+page_info.section_info["a_title"];
  }
  let grade_text_str="";
  for(let i=0;i<MathSearchDataFunc.select_stu_grade_option_arr.length;i++){
    if(page_info.menu_info&&page_info.menu_info["a_stu_grade"]==MathSearchDataFunc.select_stu_grade_option_arr[i]["value"]){
      grade_text_str=MathSearchDataFunc.select_stu_grade_option_arr[i]["text"];
      grade_text_str=grade_text_str.replace("초","");
      grade_text_str=grade_text_str+"학년";
    }
  }
  if(page_info.menu_info){
    grade_text_str+=" "+page_info.menu_info.a_stu_season+"학기";
    matter_msg_str=page_info.menu_info.a_sub_explain;
  }

  return (
    <div className="a4page" ref={printRef}>
      <div className="a4subpage">
        <div className="matter_page_title_box">
          <table>
            <colgroup>
              <col width={"125px"}></col>
              <col width={"*"}></col>
              <col width={"10%"}></col>
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <img src={prj_logo_img} alt="로고" style={{maxWidth:"100%",height:80}} />
                </td>
                <td className="matter_page_title_wrap">
                  <p>{grade_text_str}</p>
                  <p>{section_text_str}</p>
                  <p>{matter_title_str}</p>
                </td>
                <td style={{padding:1,textAlign:"center"}}>
                  <div>
                    <canvas ref={qrcode_canvas_ref} className="matter_page_qrcode_canvas"></canvas>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="matter_page_sub_title">
          {matter_msg_str}
        </div>
        <div className="matter_page_wrap">
          {get_matter_page_box_tags()}
        </div>
      </div>
    </div>
  );
});

export default ShowMatterPage;