import strFunc from "@/lib/lyg/string";
import React from "react";

function FractionHorizontalMatter(props: any) {
  let myProps = {
    matter_data: {
      idx: 0,
      part_arr: [0, "+", 0, "=", 0],
      type_id: "",
      answer_idx_arr: [4],
      is_show_answer: false,
    },
    ...props
  };
  let matter_data = myProps.matter_data;
  let operator_arr = ["+", "-", "*", "/", "(", ")", "="];

  const get_fraction_tag = (inData: any) => {
    let opt_obj = {
      part_value: "",
      ...inData
    };

    let result_tag: any = opt_obj.part_value;
    if (strFunc.str_in_array(opt_obj.part_value, operator_arr) != -1) {
      let tmp_operator_str=opt_obj.part_value;
      if (tmp_operator_str == "*") {
        tmp_operator_str = "×";
      } else if (tmp_operator_str == "/") {
        tmp_operator_str = "÷";
      }
      result_tag = (
        <span className="equal_span">
          {tmp_operator_str}
        </span>
      );
    }

    if (strFunc.str_in_array(opt_obj.part_value, operator_arr) == -1) {
      let fraction_str = opt_obj.part_value;
      let tmp_natural = "";
      if (fraction_str.indexOf("*") != -1) {
        tmp_natural = fraction_str.split("*")[0];
        fraction_str = fraction_str.replace(tmp_natural + "*", "");
      }
      let fraction_str_arr = fraction_str.split("/");
      if (fraction_str_arr[0] != 0 && fraction_str_arr[1] != 0) {
        if (fraction_str_arr[0] % fraction_str_arr[1] == 0) {
          let tmp_plus_natural = fraction_str_arr[0] / fraction_str_arr[1];
          fraction_str_arr[0] = 0;
          tmp_natural = tmp_natural + tmp_plus_natural;
        }
      }
      let is_minus = false;
      if (fraction_str_arr[0]&&fraction_str_arr[0].indexOf("-") != -1) {
        is_minus = true;
        fraction_str_arr[0] = fraction_str_arr[0].replace("-", "");
      }
      result_tag = (
        <React.Fragment>
          {is_minus&&
            <span className="equal_span" style={{padding:0}}>-</span>
          }
          <table className="matter_fraction_table">
            <tbody>
              <tr>
                {!strFunc.is_empty(tmp_natural) &&
                  <td className="fraction-natural" rowSpan={2}>{tmp_natural}</td>
                }
                {(fraction_str_arr[0] != 0 && fraction_str_arr[1] != 0) &&
                  <td >{fraction_str_arr[0]}</td>
                }
              </tr>
              <tr>
                {(fraction_str_arr[0] != 0 && fraction_str_arr[1] != 0) &&
                  <td className="fraction-denominator">{fraction_str_arr[1]}</td>
                }
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    }
    return result_tag;
  };

  const display_part_value = (part_idx: number) => {
    let part_value: any = "";
    let part_len = matter_data.part_arr.length;
    if ((part_len - 1) >= part_idx) {
      part_value = matter_data.part_arr[part_idx];
    }

    part_value = get_fraction_tag({ part_value: part_value });

    if (strFunc.str_in_array(part_idx, matter_data.answer_idx_arr) != -1) {
      if (matter_data.is_show_answer) {
        part_value = (
          <span className="answer_span">
            {part_value}
          </span>
        );
      } else {
        let tmp_anser_class_name = "answer_box";
        if (matter_data.part_arr.length - 1 == part_idx) {
          tmp_anser_class_name = "";
        }
        part_value = (
          <span className={tmp_anser_class_name} style={{ minWidth: "20px" }}>

          </span>
        );
      }
    } else {
      part_value = (
        <span>
          {part_value}
        </span>
      );
    }

    return part_value;
  };

  const get_td_tags = () => {
    let td_tags: any = matter_data.part_arr.map((item: any, idx: number) => {
      return (
        <React.Fragment key={idx}>
          {display_part_value(idx)}
        </React.Fragment>
      );
    });
    return td_tags;
  };

  return (
    <div className="matter_wrap matter_wrap_align_left">
      <span className="matter_idx_num">
        {matter_data.idx + 1}
      </span>
      {get_td_tags()}
    </div>
  );
}
export default FractionHorizontalMatter;