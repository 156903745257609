import strFunc from "@/lib/lyg/string";

function DivisionLeftMatter(props: any) {
  let myProps = {
    matter_data: {
      idx: 0,
      part_arr: [0, "+", 0, "=", 0,0],
      type_id: "",
      answer_idx_arr: [4, 5],
      is_show_answer: false,
      is_anser_min_width: true,
    },
    ...props
  };
  let matter_data = myProps.matter_data;

  const display_part_value = (part_idx: number) => {
    let part_value: any = "";
    let part_len = matter_data.part_arr.length;
    if ((part_len - 1) >= part_idx) {
      part_value = matter_data.part_arr[part_idx];
    }

    if (part_value == "*") {
      part_value = "×";
    } else if (part_value == "/") {
      part_value = "÷";
    }

    if (strFunc.str_in_array(part_idx, matter_data.answer_idx_arr) != -1) {
      if (matter_data.is_show_answer) {
        part_value = (
          <td>
            <span className="answer_span">
              {part_value}
            </span>
          </td>
        );
      } else {
        let tmp_anser_class_name = "answer_box";
        let tmp_anser_style: any = {
          color:"transparent",
        };
        if (matter_data.is_anser_min_width) {
          tmp_anser_style.minWidth = "30px";
        }
        part_value = (
          <td className={tmp_anser_class_name} style={tmp_anser_style} >
            {part_value}
          </td>
        );
      }
    } else {
      part_value = (
        <td>
          <span>
            {part_value}
          </span>
        </td>
      );
    }

    return part_value;
  };

  return (
    <div className="matter_wrap matter_wrap_align_left">
      <span className="matter_idx_num">
        {matter_data.idx + 1}
      </span>
      <table className="matter_inline_table">
        <tbody>
          <tr>
            {display_part_value(0)}
            {display_part_value(1)}
            {display_part_value(2)}
            <td>
              <span>=</span>
            </td>
            {display_part_value(4)}
            <td>
              <span>⋯</span>
            </td>
            {display_part_value(5)}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
export default DivisionLeftMatter;