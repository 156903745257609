export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
  ico?:string;
  ico_active?:string;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"h_math",name:"문제지",url:"/main/math/page/show_page",msg:"",isShow:true},
  {key:"h_page",name:"페이지",url:"/main/math/manage_page",msg:"",isShow:true},
  {key:"h_matter",name:"문제",url:"/main/math/matter/list",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"math_main",name:"홈",url:"/main",msg:"",hmenu:"h_math",isShow:false},
  {key:"math_page_use",name:"문제지",url:"/main/math/page/use_list",msg:"",hmenu:"h_math",isShow:false},
  {key:"math_show_page",name:"연산PT",url:"/main/math/page/show_page",msg:"",hmenu:"h_math",isShow:true},

  {key:"math_manage_page",name:"페이지",url:"/main/math/manage_page",msg:"",hmenu:"h_page",isShow:true},
  {key:"math_manage_menu",name:"메뉴관리",url:"/main/math/manage_menu",msg:"",hmenu:"h_page",isShow:true},
  {key:"math_manage_section",name:"섹션관리",url:"/main/math/manage_section",msg:"",hmenu:"h_page",isShow:true},
  {key:"math_manage_theme",name:"주제",url:"/main/math/theme/list",msg:"",hmenu:"h_page",isShow:true},
  // {key:"math_page_layout",name:"레이아웃",url:"/main/math/layout_list",msg:"",hmenu:"h_page",isShow:true},

  {key:"matter_list",name:"문제",url:"/main/math/matter/list",msg:"",hmenu:"h_matter",isShow:true},
  // {key:"matter_type",name:"문제유형",url:"/main/math/matter_type",msg:"",hmenu:"h_matter",isShow:true},
  {key:"matter_type_layout",name:"문제레이아웃",url:"/main/math/matter_type_layout",msg:"",hmenu:"h_matter",isShow:true},
];
export const hmenu_master_arr:string[]=[
  
];
export const smenu_master_arr:string[]=[
  
];