import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import URLFunc from '@/lib/lyg/url';
import ShowMatterPage from "@/pcomponents/common/content/math/show_page";

function ViewOfUrlPage() {
  useEffect(() => {
    let params = URLFunc.get_location_params(location);
    let page_seq=params.get("page_seq");
    if(!strFunc.is_empty(page_seq)){
      get_page_one_by_ajax({
        page_seq: page_seq,
      });
    }
  }, []);

  const ShowMatterPageRef = useRef<any>(null);
  const default_page_info = {
    a_seq: "",
    a_menu_seq: "",
    a_title: "",
    section_info: null,
    menu_info: null,
    matter_arr: [],
    matter_data_arr: [],
  };
  const [page_info, set_page_info] = useState({ ...default_page_info });

  const get_page_one_by_ajax = (inData: any) => {
    let opt_obj = {
      page_seq: "",
      ...inData
    };
    let page_seq=opt_obj.page_seq;
    if(strFunc.is_empty(page_seq)){
      return false;
    }
    let form_json_data = {
      page_seq: page_seq,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_page/get_front_one', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_page_info({
            ...default_page_info,
            ...response.data["data"]["page_info"]
          });
          
        } else {
          set_page_info({
            ...default_page_info
          });
        }
      });
  };

  return (
    <div>
      <ShowMatterPage
        ref={ShowMatterPageRef}
        page_info={page_info}
        is_show_answer={true}
      ></ShowMatterPage>
    </div>
  );
};
export default ViewOfUrlPage;