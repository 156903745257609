import MainLayout from "@/pcomponents/main/layout/layout";

function UsePageListPgae() {

  return (
    <MainLayout>
      UsePageListPgae
    </MainLayout>
  );
};
export default UsePageListPgae;