import MathMainPage from "@/pages/main/main";
import MatterTypePage from "@/pages/main/math/matter_type/list";
import ManageMenuPage from "@/pages/main/math/menu/manage_menu";
import ManageMatterpage from "@/pages/main/math/matter/list";
import ManageMaterPagePgae from "@/pages/main/math/page/mange_page";
import ManagePageLayoutListPgae from "@/pages/main/math/page_layout/list";
import SectionManagePage from "@/pages/main/math/section/manage";
import MatterTypeLayoutPage from "@/pages/main/math/matter_type_layout/list";
import UsePageListPgae from "@/pages/main/math/page/use_list";
import ShowMatterPage from "@/pages/main/math/page/show_page";
import ThemeListPage from "@/pages/main/math/theme/list";

const routerArr:object[] = [
  {
    path: "/main",
    element: <MathMainPage></MathMainPage>,
  },
  {
    path: "/main/math/matter_type",
    element: <MatterTypePage></MatterTypePage>,
  },
  {
    path: "/main/math/matter/list",
    element: <ManageMatterpage></ManageMatterpage>,
  },
  {
    path: "/main/math/manage_menu",
    element: <ManageMenuPage></ManageMenuPage>,
  },
  {
    path: "/main/math/manage_page",
    element: <ManageMaterPagePgae></ManageMaterPagePgae>,
  },
  {
    path: "/main/math/layout_list",
    element: <ManagePageLayoutListPgae></ManagePageLayoutListPgae>,
  },
  {
    path: "/main/math/manage_section",
    element: <SectionManagePage></SectionManagePage>,
  },
  {
    path: "/main/math/matter_type_layout",
    element: <MatterTypeLayoutPage></MatterTypeLayoutPage>,
  },
  {
    path: "/main/math/page/use_list",
    element: <UsePageListPgae></UsePageListPgae>,
  },
  {
    path: "/main/math/page/show_page",
    element: <ShowMatterPage></ShowMatterPage>,
  },
  {
    path: "/main/math/theme/list",
    element: <ThemeListPage></ThemeListPage>,
  },
];

export default routerArr;