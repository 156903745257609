import MainLayout from "@/pcomponents/main/layout/layout";
import ThemeListArea from "@/pages/main/math/theme/common/list";

function ThemeListPgae() {

  return (
    <MainLayout>
      <ThemeListArea></ThemeListArea>
    </MainLayout>
  );
};
export default ThemeListPgae;