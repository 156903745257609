import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
//const xColumnArr=getXcolumnJson();

const getXcolumnJson=()=>{
  let initData:any={
    table:"math_menu",
    list_id:"math_menu_list",
    x_column_list_arr:[
      {"key":"a_title","name":"제목","width":"350","is_show":"1"},
      {"key":"a_stu_grade","name":"학년","width":"90","is_show":"1"},
      {"key":"a_stu_season","name":"학기","width":"90","is_show":"1"},
      {"key":"section_title","name":"섹션","width":"140","is_show":"1"},
      {"key":"a_math_type_id","name":"문제유형","width":"220","is_show":"1"},
      {"key":"a_math_layout_id","name":"문제레이아웃","width":"150","is_show":"1"},
      {"key":"a_theme","name":"주제","width":"100","is_show":"1"},
      {"key":"a_order_num","name":"순번","width":"110","is_show":"1"},
      {"key":"a_layout_split","name":"스플릿","width":"100","is_show":"1"},
      {"key":"a_layout_design","name":"디자인","width":"100","is_show":"1"},
      {"key":"a_layout_direction","name":"방향","width":"100","is_show":"1"},
      {"key":"a_matter_cnt","name":"문제수","width":"100","is_show":"1"},
      {"key":"a_sub_explain","name":"설명","width":"200","is_show":"1"},
    ],
    list_opt_origin:{
      'now_page':1,
      'num_per_page':300,
      'order_id':'a_section_seq, a_order_num',
      'order_type':'',
      's_date_type':'',
      's_start_date':'',
      's_end_date':'',
      'sc':{},
    },
    order_id_col_arr:[
      {"value":"","text":"없음"},
    ]
  };
  initData.pri_col_arr=["a_seq"];
  const xColumnArr:any=XcolumnFunc.getDefaultXcolumn(initData);

  return xColumnArr;
};

export default getXcolumnJson;