import MainLayout from "@/pcomponents/main/layout/layout";
import ManageMaterPageList from "@/pages/main/math/page/common/manage_list";

function ManageMaterPagePgae() {

  return (
    <MainLayout>
      <ManageMaterPageList></ManageMaterPageList>
    </MainLayout>
  );
};
export default ManageMaterPagePgae;