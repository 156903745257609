import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import SearchArea from "./area/search";

function MatterTypeListArea(props: any) {
  let myProps = {
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_addon_type_layout: "1",
    s_layout_id: "",
    s_title_like: "",
    s_theme: "",

    is_need_count: "",
    is_need_info_arr: "1",
    is_no_limit: "1",
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  //const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [matter_layout_arr, set_matter_layout_arr] = useState<any>([]);
  const [math_theme_arr, set_math_theme_arr] = useState<any>([]);
  const [change_layout_seq, set_change_layout_seq] = useState("");
  const [select_theme, set_select_theme] = useState("");

  useEffect(() => {
    get_matter_layout_arr_by_ajax();
    get_math_theme_arr_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    let form_json_data = {
      ...listOpt,
      ...inOptObj
    };
    form_json_data["sc"] = listAggridRef.current.getListSc();
    setListOpt(form_json_data);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          //setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          setTimeout(() => {
            set_idx_num_all_rows();
          }, 100);
        } else {

        }
      });
  };

  const get_matter_layout_arr_by_ajax = () => {
    let form_json_data = {
      order_id: "a_order_num",
      is_need_count: "",
      is_need_info_arr: "1",
      is_no_limit: "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type_layout/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_matter_layout_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_math_theme_arr_by_ajax = () => {
    let form_json_data = {
      order_id: "a_order_num",
      is_need_count: "",
      is_need_info_arr: "1",
      is_no_limit: "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_theme/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_math_theme_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const go_change_layout_by_ajax = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (strFunc.is_empty(change_layout_seq)) {
      alert("레이아웃을 선택해주세요.");
      return false;
    }
    if (!confirm("변경하시겠습니까?")) {
      return false;
    }

    let change_data_arr: any = [];
    for (let i = 0; i < selected_row.length; i++) {
      let row_data = selected_row[i];
      change_data_arr.push({
        a_id: row_data["a_id"],
        a_layout_id: change_layout_seq,
      });
    }

    let form_json_data = {
      data_arr: change_data_arr,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };
  const go_change_theme_by_ajax=()=>{
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("변경하시겠습니까?")) {
      return false;
    }

    let change_data_arr: any = [];
    for (let i = 0; i < selected_row.length; i++) {
      let row_data = selected_row[i];
      change_data_arr.push({
        a_theme: select_theme,
        a_layout_id: change_layout_seq,
      });
    }

    let form_json_data = {
      data_arr: change_data_arr,
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let no_saved_row_idx_arr = [];
    let del_select_rows = [];
    for (let i = 0; i < selected_row.length; i++) {
      let tmp_row = selected_row[i];
      if (strFunc.is_empty(tmp_row["a_id"])) {
        no_saved_row_idx_arr.push(tmp_row["idx_num"]);
      } else {
        del_select_rows.push(tmp_row);
      }
    }
    if (no_saved_row_idx_arr.length > 0) {
      let all_rows = listAggridRef.current.getRows();
      let remain_rows = [];
      for (let i = 0; i < all_rows.length; i++) {
        let tmp_row = all_rows[i];
        if (strFunc.str_in_array(tmp_row["idx_num"], no_saved_row_idx_arr) == -1) {
          remain_rows.push(all_rows[i]);
        }
      }
      listAggridRef.current.setInfoArr(remain_rows);
      setTimeout(() => {
        set_idx_num_all_rows();
      }, 100);
    }
    if (del_select_rows.length === 0) {
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const set_idx_num_all_rows = () => {
    if(strFunc.is_empty(listAggridRef.current)){
      return false;
    }
    let all_rows = listAggridRef.current.getRows();
    for (let i = 0; i < all_rows.length; i++) {
      all_rows[i]["idx_num"] = i + 1;
    }
    listAggridRef.current.setInfoArr(all_rows);
  };

  const goSaveSelect = () => {
    let selected_row = listAggridRef.current.getRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    for (let i = 0; i < selected_row.length; i++) {
      selected_row[i]["a_order_num"] = i + 1;
    }
    if (!confirm("저장하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_type/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const add_new_row = () => {
    let tmp_rowData: any = {
      a_seq: "",
      a_title: "",
      a_order_num: "",
    };

    listAggridRef.current.addRowData({
      data: [tmp_rowData],
      focus_key: "a_title",
    });
    setInfoArr([
      ...infoArr,
      ...[tmp_rowData]
    ]);
    setTimeout(() => {
      set_idx_num_all_rows();
    }, 100);
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    return render_str;
  };

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          list={list}
          matter_layout_arr={matter_layout_arr}
          math_theme_arr={math_theme_arr}
        ></SearchArea>
      </div>
      <div className="text-right">
        <button className="btn btn-dark" onClick={() => { add_new_row(); }} >신규</button>
        <button className="btn btn-blue ml-1" onClick={() => { goSaveSelect(); }} >저장</button>
        <button className="btn btn-red ml-1" onClick={goDelete} >삭제</button>
      </div>
      <div className="text-right mt-1">
        <select className="border" value={change_layout_seq} onChange={(e: any) => {
          set_change_layout_seq(e.target.value);
        }}>
          <option value="">선택</option>
          {matter_layout_arr.map((item: any, index: number) => {
            return (
              <option key={index} value={item["a_id"]}>{item["a_title"]}</option>
            );
          })}
        </select>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_layout_by_ajax();
          }} >테마변경</button>
        <select className="border ml-1" value={select_theme} onChange={(e: any) => {
          set_select_theme(e.target.value);
        }}>
          <option value="">선택</option>
          {math_theme_arr.map((item: any, index: number) => {
            return (
              <option key={index} value={item["a_title"]}>{item["a_title"]}</option>
            );
          })}
        </select>
        <button className="btn btn-dark ml-1"
          onClick={() => {
            go_change_theme_by_ajax();
          }} >레이아웃변경</button>
      </div>
      <div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_grid: true,
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: true,
            is_add_checkbox: true,
            floatingFilter: false,
            is_add_rowDrag: true,
            onGridReady: () => {
              list({});
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer
          }}
        ></ListAggrid>
        {/* <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging> */}
      </div>
    </div>
  );
};
export default MatterTypeListArea;