import { useState, useRef, useEffect, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import strFunc from "@/lib/lyg/string";
import SearchArea from "./area/search";

function MenuListArea(props: any) {
  let myProps = {
    ...props
  };
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_addon_section: "1",
    s_section_seq: "",
    s_stu_grade: "8",
    s_stu_season: "1",
    s_math_layout_id: "",
    s_theme:"",
    // is_need_count: "",
    // is_need_info_arr: "1",
    // is_no_limit: "1",
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({"tot":0});
  const [section_info_arr, set_section_info_arr] = useState([]);
  const [change_section_seq, set_change_section_seq] = useState("");

  useEffect(() => {

  }, []);

  const list = (inData: any) => {
    let form_json_data = {
      ...listOpt,
      ...inData
    };
    form_json_data["sc"] = listAggridRef.current.getListSc();
    setListOpt(form_json_data);

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_menu/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
          setTimeout(() => {
            set_idx_num_all_rows();
          }, 100);
        } else {

        }
      });
  };

  const goDelete = (e: any) => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let no_saved_row_idx_arr=[];
    let del_select_rows=[];
    for(let i=0;i<selected_row.length;i++){
      let tmp_row=selected_row[i];
      if(strFunc.is_empty(tmp_row["a_seq"])){
        no_saved_row_idx_arr.push(tmp_row["idx_num"]);
      }else{
        del_select_rows.push(tmp_row);
      }
    }
    if(no_saved_row_idx_arr.length>0){
      let all_rows=listAggridRef.current.getRows();
      let remain_rows=[];
      for(let i=0;i<all_rows.length;i++){
        let tmp_row=all_rows[i];
        if(strFunc.str_in_array(tmp_row["idx_num"],no_saved_row_idx_arr)==-1){
          remain_rows.push(all_rows[i]);
        }
      }
      listAggridRef.current.setInfoArr(remain_rows);
      setTimeout(()=>{
        set_idx_num_all_rows();
      },100);
    }
    if(del_select_rows.length===0){
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_menu/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  }

  const set_idx_num_all_rows = () => {
    if(strFunc.is_empty(listAggridRef.current)){
      return false;
    }
    let all_rows = listAggridRef.current.getRows();
    for (let i = 0; i < all_rows.length; i++) {
      all_rows[i]["idx_num"] = i + 1;
    }
    listAggridRef.current.setInfoArr(all_rows);
  };

  const goSaveSelect = () => {
    let selected_row = listAggridRef.current.getRows();//listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if(!strFunc.is_empty(listOpt.s_section_seq)){
      for (let i = 0; i < selected_row.length; i++) {
        selected_row[i]["a_order_num"] = i + 1;
      }
    }
    if (!confirm("저장하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: selected_row
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_menu/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_change_select_section = () => {
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (change_section_seq == "") {
      alert("섹션을 선택해주세요.");
      return false;
    }
    if (confirm("선택한 메뉴를 해당섹션으로 이동하시겠습니까?") == false) {
      return false;
    }
    for (let i = 0; i < selected_row.length; i++) {
      selected_row[i]["a_section_seq"] = change_section_seq;
    }
    let form_json_data = {
      data_arr: selected_row,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_menu/write', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const add_new_row = () => {
    let tmp_rowData: any = {
      a_seq: "",
      a_stu_grade: listOpt.s_stu_grade,
      a_stu_season: listOpt.s_stu_season,
      a_section_seq: listOpt.s_section_seq,
      a_math_layout_id: listOpt.s_math_layout_id,
      a_theme: listOpt.s_theme,
      a_title: "",
      a_order_num: "",
      row_view_section: "",
      a_layout_split: "2",
      a_layout_design: "",
      a_layout_direction: "height",
      a_matter_cnt: "20",
    };
    if (strFunc.is_empty(tmp_rowData.a_stu_grade)) {
      alert("학년을 선택해주세요.");
      return false;
    };
    if (strFunc.is_empty(tmp_rowData.a_stu_season)) {
      alert("학기를 선택해주세요.");
      return false;
    };
    if (!strFunc.is_empty(tmp_rowData.a_section_seq)) {
      tmp_rowData["a_order_num"] = infoArr.length + 1;
    } else {
      alert("섹션을 선택해주세요.");
      return false;
    }

    let select_section_info = null;
    for (let i = 0; i < section_info_arr.length; i++) {
      let item = section_info_arr[i];
      if (item["a_seq"] == tmp_rowData.a_section_seq) {
        select_section_info = item;
      }
    }
    if (select_section_info != null) {
      tmp_rowData["section_title"] = select_section_info["a_title"];
    }

    listAggridRef.current.addRowData({
      data: [tmp_rowData],
      focus_key: "a_title",
    });
    setInfoArr([
      ...infoArr,
      ...[tmp_rowData]
    ]);
    setTimeout(() => {
      set_idx_num_all_rows();
    }, 100);
  };

  const go_add_matter_by_ajax=()=>{
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    
    if (selected_row.length != 1) {
      alert("한개만 선택해 주세요.");
      return false;
    }
    if(!confirm("문제를 추가 하시겠습니까?")){
      return false;
    }
    let select_one_row = selected_row[0];
    let form_json_data = {
      matter_opt: {
        title:select_one_row["a_title"],
        math_type_id:select_one_row["a_math_type_id"],
        math_layout_id:select_one_row["a_math_layout_id"],
        loop_cnt:100,
      },
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_matter/add_matter', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_add_page_by_ajax=()=>{
    let selected_row = listAggridRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    
    if (selected_row.length != 1) {
      alert("한개만 선택해 주세요.");
      return false;
    }
    if(!confirm("페이지를 추가 하시겠습니까?")){
      return false;
    }
    let select_one_row = selected_row[0];
    let form_json_data = {
      menu_seq:select_one_row["a_seq"],
      loop_cnt:1,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_page/add_page', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_init_data_by_ajax=()=>{
    if(!confirm("문제,페이지를 모두 초기화 하시겠습니까?")){
      return false;
    }
    let form_json_data = {
      is_all:"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_matter/init_data', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          alert(response.data["msg"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;

  }, []);

  const cellRenderer = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    return render_str;
  };

  return (
    <div>
      <div>
        <SearchArea
          listOpt={listOpt}
          list={list}
          section_info_arr={section_info_arr}
          set_section_info_arr={set_section_info_arr}
        ></SearchArea>
      </div>
      <div className="text-right">
        <button className="btn btn-dark" onClick={() => { add_new_row(); }} >신규</button>
        <button className="btn btn-blue ml-1" onClick={() => { goSaveSelect(); }} >저장</button>
        <button className="btn btn-red ml-1" onClick={goDelete} >삭제</button>
      </div>
      <div className="text-right mt-1">
        섹션옮기기
        <span className="ml-1">
          <select className="border" value={change_section_seq}
            onChange={(e: any) => {
              set_change_section_seq(e.target.value);
            }} >
            <option value="">선택</option>
            {section_info_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item["a_seq"]}>{item["a_title"]}</option>
              );
            })}
          </select>
        </span>
        <button className="btn btn-blue ml-1" onClick={() => { go_change_select_section(); }} >변경</button>

        <span className="ml-2">
          <button className="btn btn-dark" onClick={()=>{go_add_matter_by_ajax();}} >문제추가</button>
        </span>
        <span className="ml-2">
          <button className="btn btn-dark" onClick={()=>{go_add_page_by_ajax();}} >페이지추가</button>
        </span>
        <span className="ml-2">
          <button className="btn btn-red" onClick={()=>{go_init_data_by_ajax();}} >문제,페이지 모두초기화</button>
        </span>
      </div>
      <div>
        <ListAggrid
          ref={listAggridRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          list={list}
          gridOpt={{
            is_grid: true,
            fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
            is_idx_num: true,
            is_add_checkbox: true,
            floatingFilter: false,
            is_add_rowDrag: true,
            onGridReady: () => {
              list({});
            },
            onCellClicked: cellClickedListener,
            cellRenderer: cellRenderer
          }}
        ></ListAggrid>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot} 
          onChangePage={(now_page:number)=>{list({now_page:now_page});}}
          onChangeNumPerPage={(num_per_page:number)=>{list({now_page:1,num_per_page:num_per_page});}}></Paging>
      </div>
    </div>
  );
};
export default MenuListArea;