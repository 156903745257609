
function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt=myProps.listOpt;

  return (
    <div>
      
    </div>
  );
};
export default SearchArea;