import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    setListOpt: (inData: any) => { },
    get_page_one_by_ajax: (inData: any) => { },
    ...props
  };
  let listOpt=myProps.listOpt;
  const [section_info_arr, set_section_info_arr] = useState([]);
  const [menu_info_arr, set_menu_info_arr] = useState([]);

  useEffect(() => {
    get_section_list_by_ajax({});
  }, []);

  const on_change_list_opt = (inData: any) => {
    let opt_obj={
      key:"",
      value:"",
      add_change_data:{},
      ...inData
    };
    
    let new_list_opt = {
      ...listOpt,
    };
    new_list_opt[opt_obj.key]=opt_obj.value;
    new_list_opt={
      ...new_list_opt,
      ...opt_obj.add_change_data
    };
    myProps.setListOpt(new_list_opt);

    if(opt_obj.key=="s_stu_grade"){
      get_section_list_by_ajax({
        s_stu_grade: opt_obj.value,
      });
    }else if(opt_obj.key=="s_stu_season"){
      get_section_list_by_ajax({
        s_stu_season: opt_obj.value,
      });
    }else if(opt_obj.key=="s_section_seq"){
      get_menu_list_by_ajax({
        s_section_seq: opt_obj.value,
      });
    }else if(opt_obj.key=="s_menu_seq"){
      myProps.get_page_one_by_ajax({
        s_stu_grade: listOpt.s_stu_grade,
        s_stu_season:listOpt.s_stu_season,
        s_section_seq: listOpt.s_section_seq,
        s_menu_seq:opt_obj.value
      });
    }
    myProps.setListOpt(new_list_opt);
  };

  const get_section_list_by_ajax = (inOptObj: any) => {
    let form_json_data = {
      s_stu_grade: listOpt.s_stu_grade,
      s_stu_season: listOpt.s_stu_season,
      order_id:"a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...inOptObj
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_section/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_section_info_arr(response.data["data"]["info_arr"]);
          if(response.data["data"]["info_arr"].length>0){
            get_menu_list_by_ajax({
              s_stu_grade: form_json_data.s_stu_grade,
              s_stu_season: form_json_data.s_stu_season,
              s_section_seq:response.data["data"]["info_arr"][0]["a_seq"]
            });
          }
        } else {

        }
      });
  };
  const get_menu_list_by_ajax = (inOptObj: any) => {
    let form_json_data = {
      s_stu_grade: listOpt.s_stu_grade,
      s_stu_season: listOpt.s_stu_season,
      s_section_seq: listOpt.s_section_seq,
      order_id:"a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...inOptObj
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan_math/math_menu/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_menu_info_arr(response.data["data"]["info_arr"]);
          let add_change_data:any={
            s_stu_grade: form_json_data.s_stu_grade,
            s_stu_season: form_json_data.s_stu_season,
            s_section_seq:form_json_data.s_section_seq
          };
          if(response.data["data"]["info_arr"].length>0){
            on_change_list_opt({
              key:"s_menu_seq",value: response.data["data"]["info_arr"][0]["a_seq"],
              add_change_data:add_change_data
            });
          }else{
            on_change_list_opt({
              key:"s_menu_seq",value: "",
              add_change_data:add_change_data
            });
          }
        } else {

        }
      });
  };

  let section_select_option_arr=[{value:"",text:"전체"}];
  for(let i=0;i<section_info_arr.length;i++){
    let item=section_info_arr[i];
    section_select_option_arr.push({value:item["a_seq"],text:item["a_title"]});
  }
  let menu_select_option_arr=[{value:"",text:"전체"}];
  for(let i=0;i<menu_info_arr.length;i++){
    let item=menu_info_arr[i];
    menu_select_option_arr.push({value:item["a_seq"],text:item["a_title"]});
  }

  return (
    <div>
      <div>
        학년:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_grade_option_arr
            ]}
            value={listOpt.s_stu_grade}
            on_change={(inData: any) => {
              on_change_list_opt({ key:"s_stu_grade",value: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        학기:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={[
              {value:"",text:"전체"},
              ...MathSearchDataFunc.select_stu_season_option_arr
            ]}
            value={listOpt.s_stu_season}
            on_change={(inData: any) => {
              on_change_list_opt({ key:"s_stu_season",value: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        섹션:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={section_select_option_arr}
            value={listOpt.s_section_seq}
            on_change={(inData: any) => {
              on_change_list_opt({ key:"s_section_seq",value: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
      <div className="mt-1">
        메뉴:
        <span className="ml-2" style={{display:"inline-block"}}>
          <SelectBoxBar
            valueTextArr={menu_select_option_arr}
            value={listOpt.s_menu_seq}
            on_change={(inData: any) => {
              on_change_list_opt({ key:"s_menu_seq",value: inData.value });
            }}
          ></SelectBoxBar>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;