export interface HeadMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  isShow: boolean;
  ico?:string;
  ico_active?:string;
}
export interface SubMenuRow {
  key: string;
  name: string;
  url: string;
  msg: string;
  hmenu:string;
  isShow: boolean;
}

export const hmenu:HeadMenuRow[]=[
  {key:"h_main",name:"메인",url:"/math",msg:"",isShow:true},
];
export const smenu:SubMenuRow[]=[
  {key:"math_main",name:"연산PT",url:"/math",msg:"",hmenu:"h_main",isShow:true},
  {key:"login",name:"로그인",url:"/login",msg:"",hmenu:"",isShow:false},
  {key:"math_view_of_url",name:"답안지",url:"/math/view_of_url",msg:"",hmenu:"",isShow:false},
];
export const hmenu_master_arr:string[]=[
  
];
export const smenu_master_arr:string[]=[
  
];