//import MathSearchDataFunc from "@/pcomponents/common/content/math/search_data/math_search_data";
class MathSearchDataFunc{
  static select_stu_grade_option_arr=[
    {value:8,text:"초1"},
    {value:9,text:"초2"},
    {value:10,text:"초3"},
    {value:11,text:"초4"},
    {value:12,text:"초5"},
    {value:13,text:"초6"},
    // {value:14,text:"중1"},
    // {value:15,text:"중2"},
    // {value:16,text:"중3"},
    // {value:17,text:"고1"},
    // {value:18,text:"고2"},
    // {value:19,text:"고3"},
  ];
  static select_stu_grade_option_arr2=[
    {value:8,text:"1"},
    {value:9,text:"2"},
    {value:10,text:"3"},
    {value:11,text:"4"},
    {value:12,text:"5"},
    {value:13,text:"6"},
    {value:14,text:"7"},
    {value:15,text:"8"},
    {value:16,text:"9"},
    {value:17,text:"A"},
    {value:18,text:"B"},
    {value:19,text:"C"},
  ];
  static select_stu_season_option_arr= [
    {value:1,text:"1학기"},
    {value:2,text:"2학기"},
  ];
}
export default MathSearchDataFunc;